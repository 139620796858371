<template>
  <KinesisContainer event="scroll">
    <KinesisElement
      :strength="strength"
      class="page-background-image"
      :style="{ 'background-image': `url(${data.url})` }"
    />
  </KinesisContainer>
</template>

<script>
import { KinesisContainer, KinesisElement } from 'vue-kinesis'


export default {
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
    strength: {
      type: Number,
      default: 100,
    },
  },

  components: {
    KinesisContainer,
    KinesisElement,
  },
}
</script>

<style lang="scss">
.page-background-image {
  background-color: css-alpha('primary', 0.1);
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  background-color: var(--secondary);
  height: 60vh;
}
</style>
