<template>
  <div v-if="value.length" class="roles">
    <h2 v-if="!customTitle">{{ $tc('getInTouch') }}</h2>
    <div v-else class="roles__title" v-html="customTitle"></div>
    <div class="roles__list">
      <div class="roles__item" v-for="(item, index) in value" :key="index">
        <div class="roles__avatar" :style="{ backgroundImage: `url(${item.avatar})` }"></div>
        <div class="roles__content">
          <div class="roles__name">{{ item.name }}</div>
          <div v-if="item.title" class="roles__user-title">{{ item.title }}</div>
          <a v-if="item.email" class="roles__email" :href="`mailto:${item.email}`"
            ><FontAwesomeIcon :icon="['fal', 'envelope']" />{{ $t('sendEmail') }}</a
          >
          <a v-if="item.phone" class="roles__phone" :href="`tel:${item.phone}`"
            ><FontAwesomeIcon :icon="['fal', 'mobile']" />{{ item.phone }}</a
          >
          <div class="roles__company" v-if="showCompany(item)">
            <img
              v-if="showCompanyLogo(item)"
              class="roles__company-logo"
              :src="item.company.logo"
              :alt="item.company.name"
            />
            <span class="roles__company-name">
              {{ item.company.name }}
            </span>
          </div>
        </div>
      </div>

      <img class="mt-1" :src="logo.url" width="150" :alt="logo.name" />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
    logo: {
      type: Object,
      default: () => ({}),
    },
    customTitle: {
      type: String,
    },
  },
  computed: {
    value() {
      return this.$path('data.content') || []
    },
  },
  methods: {
    showCompanyLogo(contact) {
      if (!contact.company && !(this.$path('contact.company.logo.url') || '').includes('avatar'))
        return false
      return Boolean(contact.company.logo)
    },
    showCompany(contact) {
      if (!contact.company) return false
      return Boolean(contact.company.name)
    },
  },
}
</script>

<style lang="scss">
.roles {
  color: $dark;
  a {
    color: $dark !important;
  }
  &__list {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 2rem;
  }

  &__title {
    margin-bottom: 2rem;
  }

  &__avatar {
    $size: 80px;

    height: $size;
    width: $size;
    border-radius: 50%;
    background-size: cover;
    background-position: center;

    margin-right: 1rem;

    flex-shrink: 0;
  }

  &__subtitle {
    color: rgba($dark, 0.6);
  }

  &__user-title {
    margin: 2px 0;
  }

  &__item {
    background-color: transparent;

    display: flex;
    align-items: center;
    gap: 1rem;

    @include respond-below('phone') {
      .roles {
        &__name {
          margin-top: 10px;
        }

        &__avatar {
          $size: 80px;
          width: $size;
          height: $size;
        }

        &__email,
        &__phone {
          display: block;
          margin-top: 3px;
        }
      }
    }
  }

  &__name {
    font-weight: bold;
    font-size: 1.1em;
  }

  &__email,
  &__phone {
    text-decoration: none;
    white-space: nowrap;

    margin-right: 10px;

    svg {
      margin-right: 7px;
    }
  }

  &__company {
    display: flex;
    align-items: center;
    margin-top: 1rem;

    gap: 1rem;

    &-name {
      opacity: 0.5;
    }
    &-logo {
      max-width: 3rem;
    }
  }
}
</style>
