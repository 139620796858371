<template>
  <div class="distances">
    <div class="distances__description" v-html="description" />
    <div
      v-if="distances && distances.length"
      class="distances__items"
      :class="'distances__items--length-' + distances.length"
    >
      <template v-for="(item, index) in distances">
        <div class="distances__item" :key="index">
          <img
            class="distances__item-icon"
            v-if="item.icon"
            :alt="item.icon.name"
            :src="item.icon.url"
          />
          <div class="distances__item-description" v-html="item.description" />
        </div>
      </template>
    </div>
    <div v-if="description['area-url']">
      <ButtonComponent
        theme="primary"
        tag="a"
        :href="description['area-url']"
        label="Dette finner du mer av i nabolaget"
      />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Array,
      default: () => [],
    },
    description: {
      type: String,
    },
  },
  computed: {
    distances() {
      if (!this.data) return {}

      let value = this.data
      if (!value.find(i => i.icon)) return []
      return value
    },
  },
}
</script>

<style lang="scss">
.distances {
  $gap: 6rem;
  display: flex;
  flex-direction: column;
  gap: 4rem;
  align-items: center;
  padding: 0 $gap;

  @include respond-below('phone') {
    padding: 1rem;
  }

  &__description {
    text-align: center;
    h2 {
      margin: 0;
    }
  }
  &__items {
    width: 100%;

    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: baseline;
    gap: 3rem $gap;

    @include respond-below('phone') {
      grid-template-columns: 1fr;
    }
  }
  &__item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 1rem;
    width: clamp(100px, 200px, 300px);

    &-icon {
      max-height: 50px;
      object-fit: contain;
    }
    &-description {
      text-align: center;
      p:first-child {
        font-size: 1.2rem;
      }

      & > * {
        margin: 0;
      }
    }
  }
}
</style>
