<template>
  <div class="project-process">
    <div v-if="items && items.length" class="project-process__content">
      <div
        v-for="(item, index) in items"
        :key="index"
        class="project-process__item"
        :class="{
          'project-process__item--active': checkActive(index),
          'project-process__item--check': item['check-progress'],
        }"
      >
        <div
          class="project-process__item-icon"
          :style="{ backgroundImage: `url(${item.icon.url})` }"
          :title="item.description"
        ></div>

        <div class="project-process__item-description">
          {{ item.description }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  computed: {
    ...mapState('Project', ['item']),

    items() {
      return this.$path('item.customFields.project-progress')
    },
  },
  methods: {
    checkActive(index) {
      if (this.items[index]['check-progress']) return false
      //index = 0 is always active
      if (index === 0) return true
      if (this.items[index]['check-progress']) return true
      return false
    },
  },
}
</script>

<style lang="scss">
.project-process {
  $iconSize: 8rem;
  margin: 0 auto;

  &__content {
    display: flex;
    justify-content: center;
    gap: 2.5rem;

    @include respond-below('phone') {
      gap: 1rem;
    }
  }

  &__item {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;

    @include respond-below('phone') {
      padding: 0rem;
    }

    &-icon {
      width: $iconSize;
      height: $iconSize;
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
      opacity: 0.4;
      padding: 0.25rem;
      background-origin: content-box;
      @include respond-below('phone') {
        width: calc(#{$iconSize}/ 2);
        height: calc(#{$iconSize}/ 2);
        font-size: 0.8em;
        svg {
          font-size: 0.8em;
        }
      }
    }

    &-description {
      text-align: center;
      opacity: 0.4;
      padding: 0 0.5rem;
      font-weight: bold;
      font-size: 0.9em;
      @include respond-below('phone') {
        font-size: 0.5em;
      }
    }
    &--check,
    &--active {
      .project-process__item-description,
      .project-process__item-icon {
        opacity: 1;
      }
    }
  }
}
</style>
