<template>
  <Loader :value="promises.fetch" theme="default">
    <div class="project-page">
      <Blocks ref="blocks" :blocks="config" :custom-components="customComponents" />
    </div>
  </Loader>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import ProjectComponents from '@/components/Project'
import CustomComponents from '@/components/Custom'

import BrowserApiMixin from '@/mixins/browser-api'
import { GetCustomField } from '@/utils'
import { Blocks } from '@kvass/pagebuilder'
import Config from '../config/project'
import { animate, inView, stagger } from 'motion'

export default {
  mixins: [BrowserApiMixin('project')],

  computed: {
    ...mapState('Project', ['promises', 'item']),
    config() {
      return Config.call(this)
    },
    customComponents() {
      return {
        ...CustomComponents,
        ...ProjectComponents,
      }
    },
  },

  methods: {
    ...mapActions('Project', ['fetch']),
    GetCustomField,
    async initAnimation() {
      inView('.kvass-image-text__item', ({ target }) => {
        animate(target, { opacity: 1, transform: 'none' }, { duration: 0.8 })
      })

      inView(
        '.section-info',
        ({ target }) => {
          animate(target, { opacity: 1, transform: 'none' }, { duration: 0.8 })
        },
        { margin: '0px 0px -200px 0px' },
      )

      inView('.section-info-image', ({ target }) => {
        animate(target, { opacity: 1, transform: 'none' }, { duration: 0.8 })
      })
    },
  },
  created() {
    this.fetch()
  },
  mounted() {
    this.promises.fetch.then(() => setTimeout(() => this.initAnimation(), 100))
  },

  metaInfo() {
    return {
      title: this.$t('home'),
    }
  },

  components: {
    ...ProjectComponents,
    Blocks,
  },
}
</script>

<style lang="scss">
.nav--project-expanded + .elder-loader {
  .cta-banner {
    display: none;
  }
}

.project-page > .kpb-blocks {
  @for $i from 1 through 15 {
    & > *:nth-child(#{$i}) {
      order: $i * 10;
    }
  }
  .kvass-image-text__item {
    opacity: 0;
  }
  .hero {
    &__slider {
      $height: 80vh;

      position: relative;
      height: $height;
      width: 100%;
      background-color: css-alpha('primary', 0.1);
      img {
        object-position: center;
      }
      iframe {
        min-height: 100vh;
      }

      display: flex;
      flex-direction: column;
      @include respond-below('phone') {
        height: 100%;
        // iframe {
        //   min-height: 100vh;
        // }
      }
    }
  }

  .section__project-article-portal {
    h2 {
      margin: 0;
    }
  }

  .section-gallery {
    .kpb-gallery {
      overflow: hidden;

      &__item {
        aspect-ratio: 16 / 9;

        .kpb-photoswipe__item-caption-wrapper {
          opacity: 0;
        }

        &:hover {
          transition: 0.5s;
          transform: scale(1.1);

          .kpb-photoswipe__item-caption-wrapper {
            opacity: 1;
          }
        }
      }
    }

    .gallery-action {
      margin-top: 3rem;
      .elder-button {
        --vue-elder-button-padding-y: 0;

        padding: 0;
        border: none;

        &:hover:before {
          opacity: 0;
        }

        .elder-button__label {
          text-decoration: underline;
        }
      }
    }
  }

  .section-residentials {
    .kpb-section__container {
      @include respond-below('phone') {
        padding: 0;
      }
    }
  }

  .section-additional-contact-info {
    --section-padding-block: 6rem 3rem;

    &__content {
      @include respond-above('phone') {
        border: 1px solid $border-color;
        border-radius: $border-radius;
        padding: 2rem;
      }
    }

    &__openhouse {
      flex-grow: 1;
    }
    .roles {
      &__item {
        @include respond-above('phone') {
          flex-direction: row;
        }
      }
      &__phone {
        margin-top: auto;
      }
    }
  }

  .section-info-image,
  .section-info {
    @include respond-below('phone') {
      padding: 2rem 1rem;
    }
    &__container {
      @include respond-below('phone') {
        padding: 0;
      }
    }
  }

  .section__distances {
    .kpb-section__container {
      padding: 4rem 0;
      background-color: white;
      color: $dark;
    }
  }

  .section-info {
    z-index: get-layer('info-section');
    $translateY: -6rem;
    position: relative;

    ul {
      display: inline-block;
      padding: 0;
      margin: 0;
    }

    li p {
      margin: 0.4em;
    }

    & + .section__distances {
      padding-top: 0;
    }

    .kpb-section__container {
      text-align: center;
      position: relative;
      top: $translateY;
      max-width: 800px;
      margin: 0 auto;
      background-color: white;
      color: $dark;
      padding: 3rem;
    }

    &-content {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      background-color: white;
    }

    @include respond-below('phone') {
      top: 7rem;
      padding-bottom: 7rem;
    }

    .kpb-features {
      @include respond-below('phone') {
        gap: 1rem;
      }

      &__icon {
        .kvass-card__header {
          margin-bottom: 0;
          --kpb-features-size: 5.5rem;
        }
        @include respond-below('phone') {
          width: 100%;
        }
      }

      &__content {
        font-size: 1.2rem;
      }
    }
  }

  .kpb-blocks {
    .section-info {
      margin-bottom: 1em;

      &__content {
        align-items: center;
      }
    }
    .section-info-image {
      .kpb-section__container {
        @include respond-below('phone') {
          padding: 0;
        }
      }
      .info-image {
        &--frame {
          img {
            border: 3px solid var(--primary);
            padding: 0.4rem;
            background: transparent;
          }
        }
        &--round-edges {
          $radius: 3rem;
          img {
            border-radius: $radius;
          }

          .kvass-media-render-image__caption {
            border-radius: $radius;
          }

          @include respond-below('phone') {
            img {
              border-radius: calc(#{$radius} - 1rem);
            }
            .kvass-media-render-image__caption {
              border-radius: calc(#{$radius} - 1rem);
            }
          }
        }
      }
    }
  }
}
</style>
