import API from '@/api'

import { TransformTag } from '@/utils'

function getValueFromArray(array = [], value, match) {
  return array.find(i => i[value] === match) || {}
}

function removeEmptyHTML(item) {
  //pattern for empty html tag
  const pattern = new RegExp('(((<\\w+>)+[ \n(<br>)]*(<\\/\\w+>)+)+)|<br>', 'g')

  if (!item) return
  if (item instanceof Array) {
    return item.filter(i => {
      let result = typeof i === 'string' ? i.replace(pattern, '') : i
      if (!result) return
      return result
    })
  }

  return Object.fromEntries(
    Object.entries(item).filter(([key, value]) => {
      let result = typeof value === 'string' ? value.replace(pattern, '') : value
      if (!result) return
      return {
        [key]: result,
      }
    }),
  )
}

export default function() {
  const get = path => this.$path(path, this.$store.state)

  let projectProperties = (get('Project.item.customFields.project-properties') || []).filter(i =>
    Boolean(i.description || i.icon),
  )

  function getSubpageSlug(source) {
    return (
      (
        (get('Root.item.posts') || []).find(
          post => post.customFieldsConfig?.[0]?.source === source,
        ) || {}
      ).slug || '/'
    )
  }

  return [
    {
      component: 'Section',
      props: {
        width: 'full',
        id: 'section-hero',
        class: 'section-hero',
        style: 'padding:0;',
      },
      blocks: {
        default: [
          {
            component: 'Slider',
            props: {
              class: 'hero',
              aspectRatio: '16/9',
              class: 'hero__slider',
              manualNavigation: false,
            },
            data: get('Project.item.media.cover'),
          },
        ],
      },
    },

    {
      component: 'Section',
      props: {
        width: 'small',
        id: 'section-info',
        class: 'section-info',
        style: 'padding-bottom:0; opacity: 0; transform: translateX(-5rem);',
      },
      blocks: {
        default: [
          {
            component: 'ScrollAnchor',
            data: 'section-info',
          },

          {
            component: 'Flex',
            props: {
              gap: '4rem',
              flexDirection: 'column',
              alignItems: 'center',
              class: 'section-info__content',
            },

            blocks: {
              default: [
                {
                  component: 'Text',
                  props: {
                    'text-align': 'center',
                    class: 'project__description',
                  },

                  data: {
                    content: TransformTag(get('Project.item.description')),
                  },
                },
                {
                  component: 'Flex',
                  props: {
                    gap: '3rem',
                    alignItems: 'center',
                    flexWrap: 'wrap',
                    justifyContent: 'center',
                    class: 'hero__content-buttons',
                  },
                  blocks: {
                    default: [
                      {
                        component: 'Brochure',
                        condition: (get('Project.item.media.brochure') || []).length,
                        data: get('Project.item.media.brochure'),
                        props: {
                          style: 'padding-top: auto;',
                          placement: 'top',
                          label: getValueFromArray(
                            get('Project.item.customFields.project-hero-buttons'),
                            'type',
                            'brochure',
                          ).content,
                        },
                      },

                      {
                        component: 'Button',
                        data: {
                          to: { hash: 'lead' },
                          tag: 'router-link',
                          label: this.$t('leadFormTitle'),
                          icon: ['far', 'arrow-right'],
                          theme: 'primary',
                        },
                      },
                    ],
                  },
                },

                {
                  condition: projectProperties.length,
                  component: 'Features',
                  props: {
                    columns: projectProperties.length <= 4 ? projectProperties.length : 4,
                    placement: 'center',
                    style: 'max-width: 550px;',
                  },
                  data: {
                    content: projectProperties.map(i => {
                      return {
                        icon: i.icon,
                        content: i.description,
                      }
                    }),
                  },
                },
              ],
            },
          },
        ],
      },
    },

    {
      component: 'Section',
      condition: get('Project.item.customFields.phases.value'),
      props: {
        class: 'section__project-phases',
        width: 'medium',
      },
      blocks: {
        default: [
          {
            component: 'Phases',
            data: get('Project.item.customFields.phases'),
            props: {
              content: get('Project.item.customFields.phase-top-content'),
            },
          },
        ],
      },
    },

    {
      component: 'PageBackgroundImage',
      props: {
        strength: 130,
      },
      condition: get('Project.item.customFields.page-background-image.0'),
      data: get('Project.item.customFields.page-background-image.0'),
    },

    {
      component: 'Section',
      condition:
        get('Project.item.customFields.project-distances-description') ||
        get('Project.item.customFields.project-distances.0.icon'),
      props: {
        class: 'section__distances',
        width: 'medium',
      },
      blocks: {
        default: [
          {
            component: 'Distances',
            data: get('Project.item.customFields.project-distances'),
            props: {
              description: get('Project.item.customFields.project-distances-description'),
            },
          },
        ],
      },
    },

    {
      condition: get('Project.item.customFields.project-settings.display-contact-info'),
      component: 'Section',
      props: {
        width: 'small',
        id: 'section-additional-contact-info',
        class: 'section-additional-contact-info',
      },
      blocks: {
        default: [
          {
            component: 'Flex',
            props: {
              gap: '3rem 8rem',
              justifyContent: 'space-between',
              class: 'section-additional-contact-info__content',
            },

            blocks: {
              default: [
                {
                  component: 'Openhouse',
                  props: {
                    class: 'section-additional-contact-info__openhouse',
                  },
                  data: {
                    content: get('Project.item.openhouses'),
                  },
                  condition: () => {
                    if (!this.item) return false
                    if (get('Project.item.openhouses.length')) return true
                    return !get('Root.item.metadata.siteSettings.hideNoOpenhouse')
                  },
                },
                {
                  component: 'Roles',
                  data: {
                    content: (get('Project.item.roles.salesman') || []).slice(0, 1),
                  },
                  props: {
                    class: 'section-additional-contact-info__conten__salesman',
                    showLogo: false,
                  },
                },
              ],
            },
          },
        ],
      },
    },

    //article portal

    {
      component: 'Section',
      condition:
        get('Project.item.customFields.article-portal.max-items') === 'none'
          ? false
          : true &&
            (get('Root.item.posts') || []).find(i =>
              i.customFieldsConfig.find(config => ['article'].includes(config.source)),
            ),
      props: {
        class: 'section__project-article-portal',
        width: 'medium',
      },
      blocks: {
        default: [
          {
            component: 'Flex',
            props: {
              flexWrap: 'wrap',
              alignItems: 'flex-end',
              gap: '1rem',
              style: 'padding-bottom: 1rem;',
              class: 'project-portal-wrapper',
            },

            blocks: {
              default: [
                {
                  component: 'Text',
                  props: {
                    style: 'max-width: 1000px;',
                  },

                  data: {
                    content:
                      get('Project.item.customFields.article-portal.content') ||
                      `<h2> ${this.$t('read-latest-news')}  </h2>`,
                  },
                },
                {
                  component: 'Button',
                  condition: get('Project.item.customFields.article-portal.button-label'),
                  props: {
                    class: 'project-portal-button',
                  },
                  data: {
                    label: get('Project.item.customFields.article-portal.button-label'),
                    to: getSubpageSlug('news'),
                    tag: 'router-link',
                  },
                },
              ],
            },
          },
          {
            component: 'Portal',
            variant: 'article',
            props: {
              api: API,
              filterMethod: get('Project.item.customFields.article-portal.filterMethod') || 'none',
              filterCategories:
                get('Project.item.customFields.article-portal.filterMethod') === 'category'
                  ? get('Project.item.customFields.article-portal.categories') || []
                  : [],
              theme: 'card',
              maxItems: parseInt(get('Project.item.customFields.article-portal.max-items')) || 4,
            },
          },
        ],
      },
    },

    {
      component: 'Section',
      condition: get('Project.item.customFields.additional-content.image.url'),
      props: {
        width: 'medium',
        id: 'section-info-image',
        class: 'section-info-image',
        style: 'opacity: 0;',
      },
      blocks: {
        default: [
          {
            component: 'Media',
            data: get('Project.item.customFields.additional-content.image'),

            props: {
              aspectRatio: '16/9',
              type: get('Project.item.customFields.additional-content.image.type'),
              class: [
                {
                  'info-image--frame': (
                    get('Project.item.customFields.additional-content.settings') || []
                  ).includes('frame'),
                },

                {
                  'info-image--round-edges': (
                    get('Project.item.customFields.additional-content.settings') || []
                  ).includes('round-edges'),
                },
              ],
            },
          },
        ],
      },
    },

    {
      component: 'Section',
      condition: get('Project.item.customFields.additional-sections.0.image.url'),
      props: {
        width: get('Project.item.customFields.additional-sections-settings.size') || 'medium',
        id: 'section-image-text',
        class: 'section-image-text',
      },
      blocks: {
        default: [
          {
            component: 'ImageText',
            data: {
              content: get('Project.item.customFields.additional-sections'),
            },
          },
        ],
      },
    },

    ...(get('Project.item.customFields.additional-sections-v2') || []).map(i => {
      return {
        condition: this.$path('content', i) || this.$path('images.0', i),
        component: 'ImagesTextSection',
        data: {
          content: i,
        },
        props: {
          layout: i.layout,
          width: i.width || 'large',
          theme: i.theme || 'none',
          captionPlacement: i['image-caption-placement'],
        },
      }
    }),

    {
      component: 'Section',
      condition: get('Root.item.flatfinders.length') && get('Root.item.stats.total'),
      props: {
        width: 'medium',
        id: 'section-flatfinder',
        class: 'section-flatfinder',
        style: 'padding-bottom:1rem',
      },
      blocks: {
        default: [
          {
            component: 'ScrollAnchor',
            data: 'flatfinder',
          },
          {
            component: 'Flatfinder',
            data: {
              properties: get('Project.item.metadata.siteSettings.featuredResidentialProperties'),
              flatfinders: get('Project.item.flatfinders'),
            },
          },
        ],
      },
    },

    {
      component: 'Section',
      condition: get('Root.item.stats.total'),
      props: {
        width: 'medium',
        id: 'section-residentials',
        class: 'section-residentials',
        style: 'padding-top:1rem',
      },
      blocks: {
        default: [
          {
            component: 'ScrollAnchor',
            data: 'residentials',
          },
          {
            component: 'Residentials',
            data: {
              project: get('Project.item.id'),
            },
            props: {
              showTotalPrice: !get('Project.item.customFields.project-settings.hide-total-price'),
              title: get('Project.item.customFields.title-overrides.residentials'),
            },
          },
        ],
      },
    },

    {
      component: 'PageBackgroundImage',
      props: {
        strength: 0,
      },
      condition: get('Project.item.customFields.page-background-image.1'),
      data: get('Project.item.customFields.page-background-image.1'),
    },

    {
      component: 'Section',
      condition: get('Project.item.customFields.residential-templates.references.length'),
      props: {
        width: 'medium',
        id: 'section-residential-templates',
        class: 'section-residential-templates',
      },
      blocks: {
        default: [
          {
            component: 'ScrollAnchor',
            data: 'residential-templates',
          },
          {
            component: 'ResidentialTemplates',
            data: {
              content: get('Project.item.customFields.residential-templates'),
            },
          },
        ],
      },
    },

    {
      component: 'Section',
      condition: get('Project.item.media.gallery.length'),
      props: {
        width: 'full',
        id: 'section-gallery',
        class: 'section-gallery',
      },
      blocks: {
        default: [
          {
            component: 'ScrollAnchor',
            data: 'gallery',
          },
          {
            component: 'Gallery',
            props: {
              columns: (get('Project.item.media.gallery') || []).slice(0, 4).length,
              gap: '0.5rem',
              captionVisibility: 'hover',
              captionPlacement: 'center',
            },
            data: {
              content: (get('Project.item.media.gallery') || []).slice(0, 4),
            },
          },
          {
            component: 'Flex',
            props: {
              justifyContent: 'center',
              class: 'gallery-action',
            },

            blocks: {
              default: [
                {
                  condition: get('Project.item.media.gallery.length') > 4,
                  component: 'Button',
                  data: {
                    to: { name: 'Gallery' },
                    tag: 'router-link',
                    label: this.$t('seeMorePictures'),
                    icon: ['far', 'arrow-right'],
                  },
                },
              ],
            },
          },
        ],
      },
    },

    {
      component: 'PageBackgroundImage',
      props: {
        strength: 0,
      },
      condition: get('Project.item.customFields.page-background-image.2'),
      data: get('Project.item.customFields.page-background-image.2'),
    },

    {
      component: 'Section',
      props: {
        width: 'full',
        id: 'section-map',
        class: 'section-map',
        style: 'padding-bottom:0;',
      },
      blocks: {
        default: [
          {
            component: 'ScrollAnchor',
            data: 'map',
          },
          {
            component: 'Map',
            props: {
              aspectRatio: '21/9',
            },
            data: {
              ...get('Project.item.address'),
              coordinates: get('Project.item.address.location.coordinates'),
            },
          },
        ],
      },
    },

    {
      component: 'Section',
      props: {
        width: 'medium',
        id: 'section-contact',
        class: 'section-contact',
      },
      blocks: {
        default: [
          {
            component: 'ScrollAnchor',
            data: 'lead',
          },
          {
            component: 'Grid',
            props: {
              gap: '2rem',
              columns: '1:1',
            },
            blocks: {
              default: [
                {
                  component: 'Flex',
                  props: {
                    flexDirection: 'column',
                    gap: '2rem',
                  },
                  blocks: {
                    default: [
                      {
                        component: 'Openhouse',
                        props: {
                          class: 'project-openhouse',
                        },
                        data: {
                          content: get('Project.item.openhouses'),
                        },
                        condition: () => {
                          if (!this.item) return false
                          if (get('Project.item.openhouses.length')) return true
                          return !get('Root.item.metadata.siteSettings.hideNoOpenhouse')
                        },
                      },
                      {
                        component: 'Roles',
                        data: {
                          content: get('Project.item.roles.salesman'),
                        },
                        props: {
                          class: 'contact-page__salesman',
                          customTitle: get('Project.item.customFields.lead-top-content'),
                        },
                      },
                    ],
                  },
                },

                {
                  component: 'Lead',
                  props: {
                    options: {
                      fields: ['!title'],
                    },
                  },
                  data: {
                    reference: {
                      ref: get('Project.item.id'),
                      onModel: 'Project',
                    },
                  },
                  blocks: {
                    top: [
                      {
                        component: 'Text',
                        data: {
                          content:
                            get('Project.item.customFields.lead-form-content') ||
                            `<h2>${this.$t('leadFormTitle')} </h2>`,
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },

    {
      component: 'Section',
      condition: get('Project.item.media.attachments.length'),
      props: {
        width: 'medium',
        id: 'section-attachments',
        class: 'section-attachments',
      },
      blocks: {
        default: [
          {
            component: 'ScrollAnchor',
            data: 'attachments',
          },
          {
            component: 'Attachments',
            data: get('Project.item.media.attachments'),
          },
        ],
      },
    },

    {
      component: 'CtaBanner',
      data: {
        content: removeEmptyHTML(get('Project.item.customFields.cta-banner')),
        project: get('Project.item'),
      },
    },
  ]
}
