<template>
  <footer class="footer">
    <div class="footer__gap"></div>
    <div class="footer__container container">
      <FooterContent
        v-if="customFooterContent.length"
        :value="customFooterContent"
        class="footer__custom-content container"
      />
      <div class="footer__content">
        <div class="footer__company">
          <img :src="logo" width="150" :alt="item.name" />
        </div>

        <so-me-footer v-if="someCustomField.length !== 0" :value="someCustomField" />

        <div class="footer__menu">
          <strong class="footer__title">{{ $t('menu') }}</strong>
          <template v-for="(item, index) in navigation">
            <ActionComponent
              v-if="$path('action.name', item) !== $path('name', $route)"
              class="footer__menu-action"
              :class="{ 'footer__menu-action--empty': !item.action }"
              v-bind="item"
              :key="index"
            />
            <template v-if="item.items && item.items.length">
              <ActionComponent
                v-for="(nested, ni) in item.items"
                class="footer__menu-action footer__menu-action--indent"
                v-bind="nested"
                :key="index + '_' + ni"
              />
            </template>
          </template>
        </div>
      </div>

      <div class="footer__bottom">
        <div class="footer__copyright">
          <div>
            &copy; {{ year }} - {{ $t('pageMadeBy') }}
            <a :href="`https://www.kvass.no?ref=${currentUrl}`" target="_blank">Kvass</a>
          </div>
        </div>
        <div class="footer__links">
          <a :href="`/api/legal/privacy/project/${item.id}`" target="_blank">
            {{ $t('privacyPolicy') }} / {{ $t('cookiePolicy') }}
          </a>
          <a href="#" @click.prevent="editConsents">{{ $t('editConsents') }}</a>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import { mapState } from 'vuex'
import ActionComponent from 'vue-elder-navigation/src/action'
import API from '@/api'
import FooterContent from '@kvass/template-footer-content'
import { GetCustomField } from '@/utils'
import SoMeFooter from '@/components/SoMeFooter.vue'

export default {
  props: {
    navigation: Array,
    cookieManager: {
      type: Object,
      defualt: () => ({}),
    },
  },
  data() {
    return {
      year: new Date().getFullYear(),
    }
  },
  computed: {
    ...mapState('Root', ['item']),
    company() {
      return GetCustomField('company', this.item.customFields) || []
    },
    customFooterContent() {
      if (!this.item) return

      const content = GetCustomField('footer-content-v2', this.item.customFields) || []
      if (content.some(i => Boolean(this.$path('content', i) || this.$path('image', i)))) {
        return content.map(i => {
          let base = ''
          if (i.image) base = `<img class="mb-4" style="max-width: 230px;" src=${i.image.url}>`
          if (i.content) base = `${base}<div> ${i.content} </div>`
          return base
        })
      }

      return GetCustomField('footer-content', this.item.customFields) || []
    },
    partners() {
      return GetCustomField('partners', this.item.customFields) || []
    },
    someCustomField() {
      if (!this.item) return
      return GetCustomField('social-media', this.item.customFields) || []
    },
    logo() {
      return API.logo
    },
    currentUrl() {
      return window.location.href
    },
  },
  methods: {
    editConsents() {
      this.cookieManager.show()
    },
  },
  components: {
    ActionComponent,
    FooterContent,
    SoMeFooter,
  },
}
</script>

<style lang="scss">
.footer {
  padding: 4rem 0;
  background-color: css-alpha('secondary', 30%);

  @include respond-below('phone') {
    padding: 0;
  }

  &__content {
    display: flex;
    justify-content: space-between;
    gap: 2rem;

    @include respond-below('phone') {
      flex-direction: column;
      align-items: flex-start;
    }
  }
  &__company {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    font-size: 12px;
    max-width: 300px;
    line-height: 1.5rem;
  }
  &__partners {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    font-size: 12px;
    max-width: 300px;
    line-height: 1.5rem;
  }

  &__container {
    grid-gap: 2rem;
    background: white;
    color: $dark;
    a {
      color: $dark;
    }

    padding: 4rem 6rem !important;
    @include respond-below('phone') {
      padding: 2rem !important;
    }
  }
  &__bottom {
    opacity: 0.8;
    padding-top: 3rem;
    font-size: 14px;
    &:before {
      display: inline-block;
      content: '';
      border-bottom: 2px solid $grey;
      opacity: 0.2;
      width: 25%;
      transform: translateY(1rem);
    }

    text-align: center;

    @include respond-below('phone') {
      padding-top: 1rem;
    }
  }

  &__copyright {
    margin-top: 2rem;

    a {
      font-weight: bold;
    }
  }

  &__menu {
    display: flex;
    flex-direction: column;

    a {
      & + a {
        margin-top: 0.5rem;
      }
    }
  }

  &__menu {
    &-action {
      font-weight: normal;
      padding: 0;
      background-color: transparent !important;
      color: inherit;
      font-weight: inherit;

      cursor: pointer;

      border-bottom: 1px solid transparent;

      &:before {
        content: '';
        position: absolute;
        display: block;
        width: 100%;
        height: 2px;
        bottom: 0;
        left: 0;
        background-color: var(--secondary);
        transform: scaleX(0);
        transition: transform 0.3s ease;
      }
      &:hover {
        background-color: transparent;
        border-color: var(--primary-contrast);
        &::before {
          transform: scaleX(1);
        }
      }

      &--indent {
        margin-left: 0.5rem;
      }

      & + & {
        margin-top: 0.5rem;
      }
    }
  }

  a {
    color: inherit !important;
    text-decoration: none;
  }

  p {
    margin-top: 0;
  }

  &__custom-content {
    margin-bottom: 4rem;
    a {
      text-decoration: underline;
    }
  }

  &__title {
    display: block;

    margin-bottom: 1rem;
  }

  &__links {
    display: flex;
    flex-direction: column;
    margin-top: 1rem;
    gap: 0.3rem;
  }

  &__social-icons {
    a {
      color: var(--primary) !important;
      margin-right: 0.8rem;
    }
  }

  .user-profile {
    display: none;
  }
}
</style>
